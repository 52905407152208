import React, {useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink} from '@fortawesome/free-solid-svg-icons'
import {faWhatsapp, faFacebook, faTwitter, faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { Modal, Row, Container, Col } from 'react-bootstrap';

const ShareModal = ({url, show, handleClose}) => {

    // useRef
    const txtCopy = useRef();
    const copyText = ()=>{
        txtCopy.current.select();
        document.execCommand("copy");
    };

    return(
        <Modal show={show} onHide={handleClose}>
          <Modal.Header >
              <Modal.Title>Comparte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Container>
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <div className="text-center">
                      
                      <input type="hidden" id="textCopy" value="Joder hermano"/>
                      <a 
                        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} 
                        className="btn btn-sm  m-1"
                        style={{"borderRadius": "25px", "backgroundColor": "#3b5998"}}
                      >
                        <FontAwesomeIcon icon={faFacebook} style={{"color":"white"}}/>
                      </a>
                      <a 
                        href={`https://api.whatsapp.com/send?text=${url}`} 
                        className="btn btn-sm  m-1"
                        style={{"borderRadius": "25px", "backgroundColor": "#25D366"}}
                      >
                        <FontAwesomeIcon icon={faWhatsapp} style={{ "color":"white"}}/>
                      </a>
                      <a 
                        href={`https://twitter.com/intent/tweet?text=[text]&url=${url}&hashtags=hashtag`}
                        className="btn btn-sm  m-1"
                        style={{"borderRadius": "25px", "backgroundColor": "#1DA1F2"}}
                      >
                        <FontAwesomeIcon icon={faTwitter} style={{"color":"white"}}/>
                      </a>
                      <a 
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
                        className="btn btn-sm m-1"
                        style={{"borderRadius": "25px", "backgroundColor": "#0077b5"}}
                      >
                        <FontAwesomeIcon icon={faLinkedin} style={{"color":"white"}}/>
                      </a>
                      <button 
                        onClick={()=>{
                          copyText();
                        }}
                        className="btn btn-sm m-1"
                        style={{"borderRadius": "25px", "backgroundColor": "gray"}}
                      >
                        <FontAwesomeIcon icon={faLink} style={{"color":"white"}}/>
                      </button>
                      <br />
                      <label for="txtCopy">Enlace a copiar</label>
                      <input ref={txtCopy} id="txtCopy" value={`${url}`}  class="form-control"></input>
                    </div>
                  </Col>
                </Row>
              </Container>
          </Modal.Body>
        </Modal>
    );
}

export default ShareModal;