import React from 'react';
import {Navbar, Nav,Image} from 'react-bootstrap'
import '../Navbar/navbar.css';

const NavBar = (props) => {
    return(
        <Navbar collapseOnSelect expand="lg" className="bg-navbar nb-text" variant="dark">
        <Navbar.Brand href="/"><Image src='https://firebasestorage.googleapis.com/v0/b/argos-tool.appspot.com/o/global%2Flogod-04.png?alt=media&token=d5110bcf-cec7-4c47-ab08-31f436cd4aff' className="ajust-logo" alt="logo-argos" /></Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="mr-auto">
          </Nav>
          <Nav>
            <Nav.Link href="/en-proceso">Inicio</Nav.Link>
            <Nav.Link href="/en-proceso">Precios</Nav.Link>
            <Nav.Link href="/en-proceso">Registrarse</Nav.Link>
            <Nav.Link href="/en-proceso">Contáctanos</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
}

export default NavBar