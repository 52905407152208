import  React,{useEffect, useState, useRef} from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Card, Container, Col, Row} from 'react-bootstrap';
import {store} from "../../components/Firebase/firebase-config"
//Import icons
import SocialMediaList from "../../components/SocialMediaList";
import ShareModal from "../../components/ShareModal";



const Tarjetas = ({id}) => {
    const [socialMediaList, setSocialMediaList] = useState([]);
    const [cardData, setCardData] = useState({
      name:"",
      description:"",
      post:"",
      picture:"",
      phone:""
    });
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [url, setUrl] = useState('');
    const [loading, setloading] = useState(false);
    
    useEffect(() => {
      const isBrowser = () => typeof window !== "undefined";
      if(isBrowser){
        readBusinessCard();
        readSocialMedia();     
        setUrl(`${window.location.href}`);
        
      }    
    }, [])
  
    function View(){
      if(loading){
        return(
          <>
          <Seo title="Tarjeta de presentación" />
            <Container>
              <ShareModal url={url} handleClose={handleClose} show={show}/>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  
                  <Card className="text-center shadow mt-2" style={{"height": "700px"}}>
                    <Card.Body className="mt-5">
                        <div className="text-center">
                            <img 
                                className="justify-content-center img-center"
                                src={cardData.picture}
                                alt=""
                                style={{
                                    "borderRadius": "100px",
                                    "width": "150px",
                                    "height": "150px",
                                    "border": "1px solid #5C5C5C"
                                }} 
                            />
                        </div>
                        <h3 className="text-center">{cardData.name}</h3>
                        <h4 
                          className="text-center"
                          style={{"position":"relative", "bottom":"15px"}}
                        >{cardData.post}</h4>
                        <p className="text-center">{cardData.description}</p>
                        <div className="text-center">
                            <a 
                              href={`tel:${cardData.phone}`}
                              className="btn btn-primary btn-sm m-1"
                              style={{"borderRadius":"25px", "backgroundColor": "#a854ad", "color": "white", "border": "1px solid #a854ad"}}
                              
                              >Guardar</a>
                            <button 
                              className="btn btn-secondary btn-sm m-1"
                              style={{"borderRadius":"25px", "border": "1px solid #5C5C5C", "backgroundColor": "white", "color": "#5C5C5C"}}
                              onClick={()=>{handleShow()}}
                              >Compartir</button>
                        </div>
                        <div className="text-center ">
                          <SocialMediaList socialMediaList={socialMediaList}/>
                        </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container> 
            {/* sucio method */}
  
        </>
        )
      }else{
        return(
          <h3 className="text-center">Cargando...</h3>
        )
      }
    }
  
    const readBusinessCard = async ()=>{
      const businessCard =  store.collection('businessCard');
      const query =  businessCard.where("path", "==", `/${id}`, true).get()
      .then(async (querySnapshot) => {
          if(querySnapshot.size ==0){
              console.log("No font data")
          }else{
              querySnapshot.forEach(async(doc) => {
                setCardData({
                  ...cardData,
                  name:doc.data().name,
                  description:doc.data().description,
                  post:doc.data().post,
                  picture:doc.data().picture,
                  phone:doc.data().phone
                })
              });
          }
          setloading(true);
      })
      .catch((err)=>{
          console.log(err)
      });
    }
  
    const readSocialMedia = async ()=>{
      const socialMedia =  store.collection('socialMedia');
      const query =  socialMedia.where("path", "==", `/${id}`, true).get()
      .then(async (querySnapshot) => {
          if(querySnapshot.size ==0){
             
          }else{
              var array = [];
              querySnapshot.forEach(async(doc) => {
                  var json = {
                      type: doc.data().type,
                      url: doc.data().url,
                      id: doc.id,
                      user: doc.data().user
                  };
                  array.push(json); 
                  console.log(array);
              });
              setSocialMediaList(array);
          }
      })
      .catch((err)=>{
          console.log(err)
      });
  
    }
  
    return(
      <View /> 
    )
}

export default Tarjetas
