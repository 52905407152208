import React from 'react'
import {faInstagram, faTelegram, faWhatsapp, faFacebook, faTwitter, faLinkedin, faPinterest, faTiktok} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const SocialMediaList = ({socialMediaList}) => {
    return(
        <>
            {
                socialMediaList.map((item, i)=>{
                        if(item.type === "Instagram"){
                            return(
                                <>
                                    <a key={`url-${item}`} href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faInstagram} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                </>
                            )
                        }
                        if(item.type === "Telegram"){
                            return(
                                <>
                                    <a key={`url-${item}`} href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faTelegram} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                </>
                            )
                        }
                        if(item.type === "Whatsapp"){
                            return(
                                <>
                                    <a key={`url-${item}`} href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faWhatsapp} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                </>
                            )
                        }
                        if(item.type === "Facebook"){ 
                            return(
                                <>
                                    <a key={`url-${item}`} href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faFacebook} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                </>
                            )
                        }
                        if(item.type === "Twitter"){
                            return(
                                <>
                                    <a key={`url-${item}`} href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faTwitter} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                </>
                            )
                        }
                        if(item.type === "Linkedin"){
                            return(
                                <>
                                    <a  key={`url-${item}`} href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faLinkedin} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                </>
                            )
                        }
                        if(item.type === "Pinterest"){
                            return(
                                <>
                                    <a key={`url-${item}`} href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faPinterest} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                </>
                            )
                        }
                        if(item.type === "Tiktok"){
                            return(
                                <>
                                    <a key={`url-${item}`} href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faTiktok} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                </>
                            )
                        }
                    }
                )
            }
        </>
    )
}

export default SocialMediaList