import React from 'react';
import { Container, Col, Row , Nav,Image} from 'react-bootstrap';

const Footer = () => {
    return (
        <footer className="footer-bs pb-6">
            <Container fluid>
                <Row>
                    <Col xs={12} md={4} lg={4} className="footer-brand animated fadeInLeft">
                        <h2><Image src='https://i.postimg.cc/G3KcFqwW/logo-blanco-argos.png' className="ajust-logo-footer"/></h2>
                        <h4>Somos una agencia de clientes potenciales especializados para hacer crecer tu empresa</h4>
                        <p>© 2021 Argos agency, All rights reserved</p>
                    </Col>
                    <Col xs={12} md={4} lg={4} className="footer-social animated fadeInDown text-center">
                        <h4 className="mb-3">Síguenos en Redes</h4>
                        <ul>
                            <span className="m-3"><a href="https://instagram.com/leadsagencyca?igshid=1t8f1a24s0q3i"><img src="https://i.postimg.cc/MZ9VvCRS/facebook.png" alt="logo-facebook"/></a></span>
                            <span className="m-3"><a href="https://instagram.com/leadsagencyca?igshid=1t8f1a24s0q3i"><img src="https://i.postimg.cc/tgmG1CX6/instagram.png" alt="logo-instagram"/></a></span>
                            <span className="m-3"><a href="https://vm.tiktok.com/ZMJwocSTo/"><img src="https://i.postimg.cc/DfRsrqCj/tik-tok.png" alt="logo-tiktok" /></a></span>
                        </ul>
                        <h5>Contáctanos</h5>
                        <h6>argos.leads@gmail.com</h6>
                        <h6>whatsApp: +503 73488802</h6>
                    </Col>
                    <Col xs={12} md={4} lg={4} className="footer-nav animated fadeInUp">
                        <h4>Links de interés</h4>
                        <div className="col-md-12">
                            <ul className="pages">
                                <Nav.Link href="/en-proceso">Inicio</Nav.Link>
                                <Nav.Link href="/en-proceso">Blog</Nav.Link>
                                <Nav.Link href="/en-proceso">Agencia</Nav.Link>
                                <Nav.Link href="/en-proceso">Contáctanos</Nav.Link>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
